function platformSort(a: { platform: string }, b: { platform: string }) {
  if (a.platform < b.platform) {
    return -1;
  }
  if (a.platform > b.platform) {
    return 1;
  }
  return 0;
}

export default platformSort;
