import NextImage, { ImageLoader } from 'next/image';
import { CSSProperties, useCallback } from 'react';
import { urlFor } from '../lib/sanity';
import type { ImageWithAltType } from '../types';

const getInt = (x: string | number | undefined): number | undefined => {
  if (typeof x === 'number') {
    return x;
  }
  if (typeof x === 'string') {
    return parseInt(x, 10);
  }
  return undefined;
};

// Needed to mostly copy ImageProps from next/image as otherwise Typescript types were all messy
export type ImageProps = Omit<
  JSX.IntrinsicElements['img'],
  'src' | 'srcSet' | 'ref' | 'width' | 'height' | 'loading' | 'style'
> & {
  src: ImageWithAltType & { url?: string };
  style?: CSSProperties;
  quality?: number;
  priority?: boolean;
  loading?: 'lazy' | 'eager';
} & (
    | {
        width: number | `${number}`;
        height: number | `${number}`;
      }
    | {
        width?: never;
        height?: never;
        fill: boolean;
      }
  );

/**
 * Basic component displaying an Image coming from Sanity using next/image.
 *
 * Supports Crop/Hotspot if height and width are specified (if not using fill)
 *
 * @see https://nextjs.org/docs/api-reference/next/image
 */
export function Image({ src, priority = false, ...props }: ImageProps) {
  // Loader for Sanity. Unfortunately, as we need crop/hotspot properties here, along with the height provided,
  // We need to have this function defined in the component.
  const sanityLoader = useCallback<ImageLoader>(
    ({ width, quality = 75 }) => {
      const renderWidthInt = getInt(props.width);
      const renderHeightInt = getInt(props.height);
      const imageRatio =
        renderWidthInt && renderHeightInt ? renderWidthInt / renderHeightInt : undefined;

      let urlBuilder = urlFor(src)
        .auto('format') // Load webp if supported by browser
        .fit('max') // Don't scale up images of lower resolutions
        .width(width)
        .quality(quality);

      if (renderHeightInt && imageRatio) {
        urlBuilder = urlBuilder.height(Math.ceil(width / imageRatio));
      }

      return urlBuilder.url() || '';
    },
    [src, props.width, props.height],
  );

  return (
    <NextImage
      priority={priority}
      loader={sanityLoader}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      placeholder={src?.asset?.metadata?.lqip ? 'blur' : 'empty'}
      blurDataURL={src?.asset?.metadata?.lqip}
      alt={src?.isPresentational ? '' : src?.alt}
      src={src?.asset?.path || ''}
    />
  );
}
