import Link from 'next/link';
import { FooterColumnType } from '../../../../types';

interface ColumnLinksProps {
  columns: FooterColumnType[];
}

function ColumnLinks({ columns }: ColumnLinksProps) {
  return (
    <div className="gap-l grid grid-cols-2 md:w-2/3 lg:w-full lg:grid-cols-4">
      {Array.isArray(columns) &&
        columns.map((column) => {
          const { _key, columnTitle, links } = column;
          return (
            <div key={_key}>
              <h2 className="text-xl font-bold">{columnTitle}</h2>
              {Array.isArray(links) && (
                <ul className="gap-xxs mt-2 flex flex-col">
                  {links.map((link) => {
                    return (
                      <li key={link._key} className="group  flex items-center ">
                        <Link href={link.href || '/'} className="py-xxs relative">
                          {link.linkText}
                          <span className="bg-background absolute bottom-0 left-0 h-px w-0 duration-300 group-hover:w-full group-active:w-0" />
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          );
        })}
    </div>
  );
}

export default ColumnLinks;
