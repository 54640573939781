import { useRouter } from 'next/router';

export const Breadcrumbs = () => {
  const router = useRouter();
  const { asPath } = router;
  const cleanPath = (segment: string) => {
    const cleanSegment = segment.split('?')[0].split('#')[0];
    return decodeURIComponent(cleanSegment);
  };

  const path = asPath.split('/').filter((x) => x);
  const breadcrumbs = path.map((x, i) => {
    const href = `/${path.slice(0, i + 1).join('/')}`;
    return { title: cleanPath(x.replaceAll('-', ' ')), href };
  });

  return (
    <nav aria-label="breadcrumb" className="relative mt-8">
      <ol className="font text-md flex list-none">
        {breadcrumbs.length !== 0 && (
          <li className="text-black">
            {' '}
            <a href="/" className="hover:#00077 underline">
              Hjem
            </a>{' '}
            /
          </li>
        )}
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={breadcrumb.title} className="text-black">
            <a
              href={breadcrumb.href}
              className={`hover:#00077 ${index === breadcrumbs.length - 1 ? '' : 'underline'}`}
              aria-current={index === breadcrumbs.length - 1 ? 'page' : undefined}
            >
              {breadcrumb.title.charAt(0).toUpperCase() + breadcrumb.title.slice(1)}
            </a>

            {index < breadcrumbs.length - 1 && <span className="mx-1">/</span>}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
