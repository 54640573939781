import { motion } from 'framer-motion';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import useHeaderVisible from '../../../../store/useHeaderVisible';
import { LinkType, LoginType, MenuFeatureType, NestedMenuType } from '../../../../types';
import useMediaQuery from '../../../../utils/useMediaQuery';
import useOnClickOutside from '../../../../utils/useOnClickOutside';
import MenuCard from '../../../MenuCard';
import Logo from '../Logo';
import { menuVariant } from '../Mobile/variants/variants';
import Backdrop from './Backdrop';
import BurgerButton from './BurgerButton';
import Links from './Links';
import TopLinks from './TopLinks';

interface DesktopMenuProps {
  menu: { links: NestedMenuType[]; defaultFeature: MenuFeatureType };
  logins: LoginType[];
  header: { links: LinkType[] };
  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  isLoginOpen: boolean;
  setIsLoginOpen: Dispatch<SetStateAction<boolean>>;
}

function DesktopMenu({
  menu,
  logins,
  header,
  setIsMenuOpen,
  isMenuOpen,
  isLoginOpen,
  setIsLoginOpen,
}: DesktopMenuProps) {
  const [breadcrumb, setBreadcrumb] = useState<(string | undefined)[]>([undefined, undefined]);
  const [selectedLevel1, setSelectedLevel1] = useState<NestedMenuType[] | undefined>(undefined);
  const [selectedLevel2, setSelectedLevel2] = useState<NestedMenuType[] | undefined>(undefined);
  const [activeFeature, setActiveFeature] = useState(menu.defaultFeature);
  const { setIsVisible } = useHeaderVisible();

  const isNarrow = useMediaQuery('(max-width: 1023px)');
  const navRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(navRef, () => {
    if (!isNarrow) setIsMenuOpen(false);
  });

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const resetSelected = () => {
    setSelectedLevel1(undefined);
    setSelectedLevel2(undefined);
    setActiveFeature(menu.defaultFeature);
    setBreadcrumb([undefined, undefined]);
  };

  const handleDropdownClick = (depthLevel: number, linkItem: NestedMenuType) => {
    if (depthLevel === 0) {
      setSelectedLevel1((prev) => {
        if (prev === linkItem.links) {
          return undefined;
        }
        return linkItem.links;
      });
      setSelectedLevel2(undefined);
      setBreadcrumb([linkItem.link?.linkText, undefined]);
      if (linkItem.menuFeature) {
        setActiveFeature(linkItem.menuFeature);
      } else {
        setActiveFeature(menu.defaultFeature);
      }
    } else {
      setSelectedLevel2((prev) => {
        if (prev === linkItem.links) {
          return undefined;
        }
        return linkItem.links;
      });
      setBreadcrumb((prev) => [prev[0], linkItem.link?.linkText]);
    }
  };

  const handleMenuClick = () => {
    setIsMenuOpen((prev) => {
      if (isNarrow) {
        if (prev) {
          document.body.style.overflow = 'unset';
        } else {
          document.body.style.overflow = 'hidden';
        }
      }
      return !prev;
    });

    if (isLoginOpen) {
      setIsLoginOpen(false);
    }

    if (!isMenuOpen) resetSelected();
  };

  return (
    <>
      <nav
        onFocus={() => {
          setIsVisible(true);
        }}
        ref={navRef}
        className="mx-auto flex h-full max-w-[1440px] items-center justify-between px-20 max-lg:hidden"
      >
        <Logo isMenuOpen={isMenuOpen} />
        <TopLinks
          isMenuOpen={isMenuOpen}
          setIsLoginOpen={setIsLoginOpen}
          closeMenu={handleCloseMenu}
          links={header.links}
          isLoginOpen={isLoginOpen}
          logins={logins}
        />
        <BurgerButton handleMenuClick={handleMenuClick} isMenuOpen={isMenuOpen} />

        <motion.div
          initial="closed"
          animate={isMenuOpen ? 'open' : 'closed'}
          variants={menuVariant}
          style={isMenuOpen ? {} : { pointerEvents: 'none' }}
          className="text-background bg-primary fixed inset-x-0 top-0 overflow-hidden text-lg"
          aria-hidden={!isMenuOpen}
        >
          <div className="invisible-scrollbar relative mx-auto mt-20 max-h-[calc(100vh-80px)] max-w-[1440px] items-center justify-between overflow-y-scroll px-20 py-20">
            <div className="relative flex gap-8">
              <motion.div className="grid h-max basis-3/4 grid-cols-3">
                <Links
                  depthLevel={0}
                  links={menu.links}
                  isMenuOpen={isMenuOpen}
                  breadcrumb={breadcrumb}
                  selectedLevel1={selectedLevel1}
                  selectedLevel2={selectedLevel2}
                  handleBurgerClick={handleMenuClick}
                  handleDropdownClick={handleDropdownClick}
                />
                <Links
                  depthLevel={1}
                  breadcrumb={breadcrumb}
                  links={selectedLevel1}
                  isMenuOpen={isMenuOpen}
                  selectedLevel1={selectedLevel1}
                  selectedLevel2={selectedLevel2}
                  handleBurgerClick={handleMenuClick}
                  handleDropdownClick={handleDropdownClick}
                />
                <Links
                  depthLevel={2}
                  breadcrumb={breadcrumb}
                  links={selectedLevel2}
                  isMenuOpen={isMenuOpen}
                  selectedLevel1={selectedLevel1}
                  selectedLevel2={selectedLevel2}
                  handleBurgerClick={handleMenuClick}
                  handleDropdownClick={handleDropdownClick}
                />
              </motion.div>
              <motion.div layoutId="menuCard" className="h-[500px] w-full basis-1/4">
                <MenuCard menuFeature={activeFeature} isMenuOpen={isMenuOpen} />
              </motion.div>
            </div>
          </div>
        </motion.div>
      </nav>
      <Backdrop isMenuOpen={isMenuOpen} />
    </>
  );
}

export default DesktopMenu;
