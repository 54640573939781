import Link from 'next/link';
import LogoWordMark from '../../icons/LogoWordMark';

interface LogoProps {
  isMenuOpen: boolean;
}

function Logo({ isMenuOpen }: LogoProps) {
  return (
    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
    <Link href="/" className="z-10">
      <LogoWordMark
        className={`${
          isMenuOpen ? 'text-background' : 'text-secondary'
        } h-full w-36 transition duration-300 ease-in-out`}
      />
      <span className="sr-only">Til forsiden</span>
    </Link>
  );
}

export default Logo;
