import { useEffect, useState } from 'react';

const isBrowser = typeof window !== 'undefined';

const useMediaQuery = (query: string, defaultState = false) => {
  const [state, setState] = useState(
    isBrowser ? () => window.matchMedia(query).matches : defaultState,
  );

  useEffect(() => {
    let mounted = true;
    const mediaQueryList = window.matchMedia(query);
    const onChange = () => {
      if (!mounted) {
        return;
      }
      setState(!!mediaQueryList.matches);
    };

    mediaQueryList.addEventListener('change', onChange);
    setState(mediaQueryList.matches);

    return () => {
      mounted = false;
      mediaQueryList.removeEventListener('change', onChange);
    };
  }, [query]);

  return state;
};

export default useMediaQuery;
