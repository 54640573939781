import { AnimatePresence, motion } from 'framer-motion';
import { RiCloseLine } from 'react-icons/ri';
import Icon from '../../../icons/Icon';

interface BurgerButtonProps {
  isMenuOpen: boolean;
  handleMenuClick: () => void;
}

const BurgerButtonVariant = {
  open: {
    opacity: 1,
    transition: { duration: 0.3, ease: 'easeInOut' },
  },
  closed: {
    opacity: 0,
    transition: { duration: 0.3, ease: 'easeInOut' },
  },
};

function BurgerButton({ isMenuOpen, handleMenuClick }: BurgerButtonProps) {
  return (
    <AnimatePresence mode="wait" initial={false}>
      <button
        type="button"
        data-collapse-toggle="navbar"
        aria-expanded={isMenuOpen}
        onClick={() => handleMenuClick()}
        className={`${
          !isMenuOpen
            ? 'bg-primary text-on-primary  border-transparent'
            : 'border-secondary text-background '
        }   z-10 rounded-[24px] border duration-300 ease-in-out`}
      >
        {!isMenuOpen ? (
          <motion.div
            key="open"
            className="flex w-full items-center overflow-hidden p-3"
            initial="closed"
            animate="open"
            exit="closed"
            variants={BurgerButtonVariant}
          >
            <Icon icon="burger" className="h-6 w-6" />
            <span className="sr-only">Meny</span>
          </motion.div>
        ) : (
          <motion.div
            key="close"
            className="flex w-full items-center overflow-hidden p-3"
            initial="closed"
            animate="open"
            exit="closed"
            variants={BurgerButtonVariant}
          >
            <RiCloseLine className="h-6 w-6" />
            <span className="sr-only">Lukk</span>
          </motion.div>
        )}
      </button>
    </AnimatePresence>
  );
}

export default BurgerButton;
