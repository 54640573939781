/* eslint-disable no-nested-ternary */
import { useMotionValueEvent, useScroll } from 'framer-motion';
import { useState } from 'react';
import useHeaderVisible from '../../../store/useHeaderVisible';
import { MenuType } from '../../../types';
import cn from '../../../utils/cn';
import DesktopMenu from './Desktop/DesktopMenu';
import MobileMenu from './Mobile/MobileMenu';

type HeaderMenuProps = Pick<MenuType, 'header' | 'menu' | 'logins'>;

function HeaderMenu({ header, menu, logins }: HeaderMenuProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const { isVisible, setIsVisible } = useHeaderVisible();

  const { scrollY } = useScroll();
  useMotionValueEvent(scrollY, 'change', (latest) => {
    if (isMenuOpen) return;
    if (isLoginOpen) setIsLoginOpen(false);

    if (latest < scrollY.getPrevious()) {
      setIsVisible(true);
    } else if (latest > 80 && latest > scrollY.getPrevious()) {
      setIsVisible(false);
    }
  });

  // TODO: atleast on DESKTOP
  // TODO: handle focus/keyboard navigation -> REACT forwardRef() & useImperativeHandle()
  // TODO: add aria attributes for screen-readers

  return (
    <header
      className={cn(
        'bg-background border-primary/5 fixed inset-x-0 top-0 z-50 h-20 select-none border-b transition-transform duration-200',
        isVisible ? 'translate-y-0' : '-translate-y-20',
      )}
    >
      <div className="max-lg:hidden">
        <DesktopMenu
          menu={menu}
          logins={logins}
          header={header}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          isLoginOpen={isLoginOpen}
          setIsLoginOpen={setIsLoginOpen}
        />
      </div>

      <div className="h-full lg:hidden">
        <MobileMenu
          menu={menu}
          header={header}
          logins={logins}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
      </div>
    </header>
  );
}

export default HeaderMenu;
