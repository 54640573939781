function Burger({ className = '' }: { className?: string }) {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="menu">
        <path
          id="Icon"
          d="M3.5 14.5H24.5M3.5 7.5H24.5M3.5 21.5H24.5"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default Burger;
