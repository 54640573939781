import cn from '../utils/cn';

interface SectionProps {
  color?: 'background' | 'primary' | 'info' | 'info-light' | 'tertiary';
  className?: string;
  children: React.ReactNode;
  cta?: boolean;
}
function Section({ color, cta, className, children }: SectionProps) {
  return (
    <section
      className={cn(
        'bg-transparent',
        color === 'background' ? 'bg-background text-on-background' : '',
        color === 'primary' ? 'bg-primary text-on-primary' : '',
        color === 'info-light' ? 'bg-[#edf3ff]' : '',
        color === 'info' ? 'bg-[#d9e4f9]' : '',
        color === 'tertiary' ? 'bg-tertiary' : '',
      )}
    >
      <div
        className={cn(
          'max-w-8xl mx-auto px-6 sm:px-10  lg:px-20 ',
          color ? 'py-16 sm:py-20 lg:py-28' : 'my-16 sm:my-20 lg:my-28',
          cta ? 'pb-3 sm:pb-4 lg:pb-4' : '',
          className,
        )}
      >
        {children}
      </div>
    </section>
  );
}

export default Section;
