import Head from 'next/head';
import { useRouter } from 'next/router';
import { urlFor } from '../lib/sanity';
import { DefaultSEOType, SEOType } from '../types';

interface SEOProps {
  settingsSEO: { titleSuffix: string };
  seo?: SEOType;
  defaultSEO: DefaultSEOType;
}

function SEO({ defaultSEO, seo, settingsSEO }: SEOProps) {
  const { asPath } = useRouter();
  const basePath = 'https://mollerbil.no';

  const siteTitle = `${seo?.title || defaultSEO.title} - ${settingsSEO?.titleSuffix}`;
  const siteDescription = seo?.description || defaultSEO.description;
  const siteImage = seo?.image || defaultSEO?.image;
  const siteURL = basePath + asPath;

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      {(process.env.NEXT_PUBLIC_SANITY_DATASET_TEMP === 'development' || seo?.disallowRobots) && (
        <meta name="robots" content="noindex" />
      )}

      <link rel="icon" href="/assets/images/favicon.ico" type="image/x-icon" />

      {/* Primary Meta Tags */}
      <title>{siteTitle}</title>
      <meta name="title" content={siteTitle} />
      <meta name="description" content={siteDescription} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteURL} />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={siteDescription} />
      {siteImage?.asset && (
        <meta
          property="og:image"
          content={urlFor(siteImage).width(1200).height(630).url()?.toString()}
        />
      )}

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={siteURL} />
      <meta property="twitter:title" content={siteTitle} />
      <meta property="twitter:description" content={siteDescription} />
      {siteImage?.asset && (
        <meta
          property="twitter:image"
          content={urlFor(siteImage).width(1200).height(630).url()?.toString()}
        />
      )}
    </Head>
  );
}

export default SEO;
