import { FooterColumnType, LinkType } from '../../../../types';
import ColumnLinks from '../Parts/ColumnLinks';
import DescriptionLinks from '../Parts/DescriptionLinks';

interface MiddleProps {
  columns: FooterColumnType[];
  description?: string;
  links: LinkType[];
}

function Middle({ columns, links, description }: MiddleProps) {
  return (
    <section className="gap-xxl flex max-lg:flex-col lg:gap-[90px]">
      <DescriptionLinks links={links} description={description} />
      <ColumnLinks columns={columns} />
    </section>
  );
}

export default Middle;
