import { easeOut, motion } from 'framer-motion';
import Link from 'next/link';
import { FiChevronRight } from 'react-icons/fi';
import { NestedMenuType } from '../../../../types';
import cn from '../../../../utils/cn';

interface LinksProps {
  links?: NestedMenuType[];
  isMenuOpen: boolean;
  depthLevel: number;
  breadcrumb: (string | undefined)[];
  selectedLevel1: NestedMenuType[] | undefined;
  selectedLevel2: NestedMenuType[] | undefined;
  handleBurgerClick: () => void;
  handleDropdownClick: (depthLevel: number, linkItem: NestedMenuType) => void;
}

function Links({
  links,
  isMenuOpen,
  depthLevel,
  breadcrumb,
  selectedLevel1,
  selectedLevel2,
  handleBurgerClick,
  handleDropdownClick,
}: LinksProps) {
  if (!links) return null;

  const key = () => {
    switch (depthLevel) {
      case 1:
        return breadcrumb[0];
      case 2:
        return breadcrumb[1];
      default:
        return 'firstLevel';
    }
  };

  const levelStyles = (depth: number, linkItem: NestedMenuType) => {
    switch (depth) {
      case 0:
        if (!selectedLevel1) {
          return 'opacity-100';
        }
        if (breadcrumb.includes(linkItem.link?.linkText)) {
          return 'opacity-100 ';
        }
        return 'opacity-60 ';

      case 1:
        if (!selectedLevel2) {
          return 'opacity-100';
        }
        if (breadcrumb.includes(linkItem.link?.linkText)) {
          return 'opacity-100';
        }
        return 'opacity-60';
      default:
        return ``;
    }
  };

  return (
    <div className="border-r border-white/20 text-xl last:border-none">
      <motion.div
        key={key()}
        initial={{ opacity: 0, x: -12 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -12 }}
        transition={{
          duration: 0.3,
          ease: easeOut,
        }}
      >
        <ul className="flex flex-col gap-2 px-2">
          {links.map((linkItem) => {
            if (!linkItem.link) return null;

            // Has submenu
            if (linkItem.links) {
              return (
                <motion.li
                  key={linkItem.link.linkText}
                  animate={{ opacity: 1, x: 0 }}
                  initial={{ opacity: 0.5, x: -12 }}
                >
                  <button
                    type="button"
                    tabIndex={isMenuOpen ? 0 : -1}
                    aria-expanded={
                      depthLevel === 0
                        ? linkItem.link?.linkText === breadcrumb[0]
                        : linkItem.link?.linkText === breadcrumb[1]
                    }
                    onClick={() => handleDropdownClick(depthLevel, linkItem)}
                    className={cn(
                      `group relative flex w-full items-center justify-between rounded-md border border-transparent p-3 py-2 duration-150 hover:opacity-100 active:opacity-60`,
                      levelStyles(depthLevel, linkItem),
                    )}
                  >
                    <div
                      className={cn(
                        `flex w-full items-center justify-between gap-2 text-start`,
                        breadcrumb.includes(linkItem.link?.linkText) ? 'justify-between' : '',
                      )}
                    >
                      {linkItem.link.linkText}
                      <FiChevronRight className="h-5 min-h-[20px] w-5 min-w-[20px]" />
                    </div>
                  </button>
                </motion.li>
              );
            }

            // No submenu, link goes directly to url
            return (
              <motion.li
                key={linkItem.link.linkText}
                animate={{ opacity: 1, x: 0, filter: 'blur(0px)' }}
                initial={{ opacity: 0.5, x: -20, filter: 'blur(1px)' }}
              >
                <Link
                  href={linkItem.link?.href || '/'}
                  tabIndex={isMenuOpen ? 0 : -1}
                  className={cn(
                    `group relative flex w-full items-center justify-between rounded-md p-3 py-2 underline-offset-4 duration-150 hover:underline hover:opacity-100 active:opacity-60`,
                    levelStyles(depthLevel, linkItem),
                  )}
                  onClick={() => {
                    handleBurgerClick();
                  }}
                >
                  {linkItem.link?.linkText}
                </Link>
              </motion.li>
            );
          })}
        </ul>
      </motion.div>
    </div>
  );
}

export default Links;
