import { AnimatePresence, motion } from 'framer-motion';

function Backdrop({ isMenuOpen }: { isMenuOpen: boolean }) {
  return (
    <AnimatePresence>
      {isMenuOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed left-0 top-0 -z-10 h-screen w-screen bg-black/70"
        />
      )}
    </AnimatePresence>
  );
}

export default Backdrop;
