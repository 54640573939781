import { twMerge } from 'tailwind-merge';

function Bilhold({ className }: { className?: string }) {
  return (
    <svg
      className={twMerge(`h-5 w-5`, className)}
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.77096 4.49719V4.50719C8.93694 4.51258 11.5219 7.07892 11.5219 10.2336C11.5219 13.3916 8.9315 15.96 5.76096 15.96C2.59043 15.96 0 13.3916 0 10.2336V0H3.44057V10.2236C3.44057 11.5028 4.49075 12.5422 5.77096 12.5422C7.0512 12.5422 8.1013 11.5028 8.1013 10.2236C8.1013 8.9444 7.0612 7.9051 5.77096 7.9051V4.50719C5.76763 4.50718 5.76429 4.50718 5.76096 4.50718L5.77096 4.49719Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Bilhold;
