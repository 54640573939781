function LogoWordMark({ className = '' }) {
  return (
    <svg
      width="95"
      height="18"
      viewBox="0 0 95 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.651611 0.0690918H5.20275L10.0096 11.7964H10.2141L15.0209 0.0690918H19.5721V17.5236H15.9925V6.16284H15.8476L11.3306 17.4384H8.89309L4.37604 6.12023H4.23116V17.5236H0.651611V0.0690918Z"
        fill="currentColor"
      />
      <path d="M39.2142 0.0690918V17.5236H35.5835V0.0690918H39.2142Z" fill="currentColor" />
      <path d="M45.1532 0.0690918V17.5236H41.5226V0.0690918H45.1532Z" fill="currentColor" />
      <path
        d="M53.4275 17.7793C52.0809 17.7793 50.9218 17.5066 49.9503 16.9611C48.9843 16.41 48.24 15.6316 47.7173 14.6259C47.1946 13.6145 46.9332 12.4185 46.9332 11.0378C46.9332 9.69125 47.1946 8.50943 47.7173 7.49239C48.24 6.47534 48.9758 5.68273 49.9247 5.11455C50.8792 4.54636 51.9986 4.26227 53.2826 4.26227C54.1463 4.26227 54.9503 4.40148 55.6946 4.67989C56.4446 4.95261 57.098 5.36455 57.6548 5.91568C58.2173 6.46682 58.6548 7.16 58.9673 7.99523C59.2798 8.82477 59.4361 9.79637 59.4361 10.91V11.9072H48.3821V9.65716H56.0184C56.0184 9.13443 55.9048 8.67136 55.6775 8.26796C55.4503 7.86455 55.1349 7.54921 54.7315 7.32193C54.3338 7.08898 53.8707 6.9725 53.3423 6.9725C52.7912 6.9725 52.3025 7.10034 51.8764 7.35602C51.4559 7.60602 51.1264 7.94409 50.8878 8.37023C50.6491 8.79068 50.527 9.25943 50.5213 9.77648V11.9157C50.5213 12.5634 50.6406 13.1231 50.8792 13.5947C51.1236 14.0663 51.4673 14.4299 51.9105 14.6856C52.3537 14.9413 52.8792 15.0691 53.4872 15.0691C53.8906 15.0691 54.2599 15.0123 54.5951 14.8986C54.9304 14.785 55.2173 14.6145 55.4559 14.3873C55.6946 14.16 55.8764 13.8816 56.0014 13.552L59.3593 13.7736C59.1889 14.5805 58.8395 15.285 58.3111 15.8873C57.7883 16.4839 57.1122 16.9498 56.2826 17.285C55.4588 17.6145 54.5071 17.7793 53.4275 17.7793Z"
        fill="currentColor"
      />
      <path
        d="M61.2054 17.5236V4.43273H64.7253V6.71682H64.8616C65.1003 5.90432 65.5008 5.29068 66.0633 4.87591C66.6258 4.45546 67.2736 4.24523 68.0065 4.24523C68.1883 4.24523 68.3844 4.25659 68.5946 4.27932C68.8048 4.30205 68.9895 4.3333 69.1486 4.37307V7.59466C68.9781 7.54352 68.7423 7.49807 68.4412 7.4583C68.14 7.41852 67.8645 7.39864 67.6145 7.39864C67.0804 7.39864 66.6031 7.51512 66.1826 7.74807C65.7679 7.97534 65.4383 8.29352 65.194 8.70261C64.9554 9.11171 64.8361 9.5833 64.8361 10.1174V17.5236H61.2054Z"
        fill="currentColor"
      />
      <path
        d="M76.2483 17.5236V0.0690918H80.3506C81.5665 0.0690918 82.5693 0.279319 83.3591 0.699774C84.1489 1.11455 84.7369 1.67421 85.1233 2.37875C85.5097 3.07761 85.7028 3.85318 85.7028 4.70546C85.7028 5.45546 85.5693 6.07477 85.3023 6.56341C85.0409 7.05205 84.6943 7.43841 84.2625 7.7225C83.8364 8.00659 83.3733 8.21682 82.8733 8.35318V8.52364C83.4074 8.55773 83.9443 8.74523 84.4841 9.08614C85.0239 9.42705 85.4756 9.91568 85.8392 10.552C86.2028 11.1884 86.3847 11.9668 86.3847 12.8873C86.3847 13.7623 86.1858 14.5492 85.7881 15.2481C85.3903 15.9469 84.7625 16.5009 83.9045 16.91C83.0466 17.3191 81.9301 17.5236 80.5551 17.5236H76.2483ZM78.3619 15.6486H80.5551C81.9358 15.6486 82.9159 15.3816 83.4955 14.8475C84.0807 14.3077 84.3733 13.6543 84.3733 12.8873C84.3733 12.2964 84.2227 11.7509 83.9216 11.2509C83.6205 10.7452 83.1915 10.3418 82.6347 10.0407C82.0778 9.73386 81.4187 9.58046 80.6574 9.58046H78.3619V15.6486ZM78.3619 7.73955H80.2824C80.9187 7.73955 81.4926 7.61455 82.004 7.36455C82.521 7.11455 82.9301 6.76227 83.2312 6.30773C83.5381 5.85318 83.6915 5.31909 83.6915 4.70546C83.6915 3.93841 83.4244 3.28784 82.8903 2.75375C82.3562 2.21398 81.5097 1.94409 80.3506 1.94409H78.3619V7.73955Z"
        fill="currentColor"
      />
      <path
        d="M88.9049 17.5236V4.43273H90.9163V17.5236H88.9049ZM89.9277 3.25091C89.5356 3.25091 89.1976 3.11739 88.9135 2.85034C88.6351 2.5833 88.4958 2.26227 88.4958 1.88727C88.4958 1.51227 88.6351 1.19125 88.9135 0.924205C89.1976 0.65716 89.5356 0.523638 89.9277 0.523638C90.3197 0.523638 90.6549 0.65716 90.9333 0.924205C91.2174 1.19125 91.3595 1.51227 91.3595 1.88727C91.3595 2.26227 91.2174 2.5833 90.9333 2.85034C90.6549 3.11739 90.3197 3.25091 89.9277 3.25091Z"
        fill="currentColor"
      />
      <path d="M94.9316 0.0690918V17.5236H92.9203V0.0690918H94.9316Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8611 4.10068C23.6188 4.59384 21.1345 7.3936 21.1345 10.7736C21.1345 14.1536 23.6188 16.9534 26.8611 17.4465V14.0739C25.4529 13.6377 24.4301 12.3251 24.4301 10.7736C24.4301 9.2221 25.4529 7.9095 26.8611 7.47331V4.10068ZM28.9077 14.074C30.316 13.6379 31.3389 12.3252 31.3389 10.7736C31.3389 9.22201 30.316 7.90935 28.9077 7.47324V4.10065C32.1501 4.5937 34.6345 7.39351 34.6345 10.7736C34.6345 14.1537 32.1501 16.9535 28.9077 17.4465V14.074Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default LogoWordMark;
