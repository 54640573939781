function removeEntriesWithUnderscore(
  obj: Record<string, string>,
): { platform: string; url: string }[] {
  const result: { platform: string; url: string }[] = [];
  Object.entries(obj).forEach(([key, value]) => {
    if (!key.includes('_')) {
      result.push({ platform: key, url: value });
    }
  });
  return result;
}

export default removeEntriesWithUnderscore;
