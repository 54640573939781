export const menuVariant = {
  open: {
    height: 'auto',
    transition: {
      ease: 'easeInOut',
      duration: 0.3,
    },
  },
  closed: {
    height: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.3,
    },
  },
};

export const headerItemVariant = {
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const menuContentVariant = {
  visible: (direction: number) => ({
    x: 0,
    transition: {
      ease: 'easeOut',
      duration: 0.3,
    },
  }),
  enter: (direction: number) => ({
    x: `${100 * direction}%`,
  }),
};

export const menuListVariant = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.6, ease: 'easeInOut' } },
};
