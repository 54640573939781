import { FooterMenuType, SocialMediaType } from '../../../types';
import LogoSymbol from '../../icons/LogoSymbol';
import Bottom from './Section/Bottom';
import Middle from './Section/Middle';

interface FooterProps {
  footerMenu: FooterMenuType;
  socialMedia: SocialMediaType;
}

function Footer({ footerMenu, socialMedia }: FooterProps) {
  const { bottom, columns, links, description } = footerMenu;

  return (
    <footer
      className="bg-primary text-background px-6 py-12 sm:px-10 sm:pt-16 lg:px-20
    lg:pt-20"
    >
      <div className="mx-auto flex max-w-7xl flex-col gap-5">
        <LogoSymbol className="text-secondary mb-5 sm:h-14 sm:w-14 " />
        <Middle links={links} columns={columns} description={description} />
        <Bottom bottom={bottom} socialMedia={socialMedia} />
      </div>
    </footer>
  );
}

export default Footer;
