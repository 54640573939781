/* eslint-disable prefer-destructuring */
import Link from 'next/link';
import { SocialMediaType } from '../../../../types';
import removeEntriesWithUnderscore from '../../../../utils/removeEntriesWithUnderscore';
import platformSort from '../../../../utils/soMeSort';
import Icon from '../../../icons/Icon';

interface SocialMediaProps {
  socialMedia: SocialMediaType;
}

const platformColors: { [key: string]: string } = {
  facebook: 'bg-[#1877f2]',
  instagram: 'bg-gradient-to-tr from-[#f5bf3c] from-10% via-50% to-75% via-[#e94a5a] to-[#ac35c7]',
  tiktok: 'bg-gradient-to-b from-[#0fedf2] to-[#f7014a]',
  youtube: 'bg-[#f44336]',
  snapchat: 'bg-[#f7f301]',
};

function SoMe({ socialMedia }: SocialMediaProps) {
  const socialMediaArray = removeEntriesWithUnderscore(socialMedia).sort(platformSort);
  return (
    <div className="gap-l flex">
      {socialMediaArray.map((item) => {
        const { platform, url } = item;

        return (
          <Link key={url} href={url}>
            <div className="group relative grid aspect-square h-10 w-10 place-items-center items-center rounded-full border border-white/5 bg-white/5">
              <div
                className={`absolute inset-2 rounded-full duration-300 ${platformColors[platform]} z-0 opacity-0 group-hover:inset-0 group-hover:animate-pulse group-hover:opacity-100`}
              />
              <span className="sr-only">{platform}</span>
              <Icon icon={platform} className="pointer-events-none relative h-6 w-6" />
            </div>
          </Link>
        );
      })}
    </div>
  );
}

export default SoMe;
