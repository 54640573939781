import { AnimatePresence, motion } from 'framer-motion';
import { RiCloseLine } from 'react-icons/ri';
import Icon from '../../../icons/Icon';

interface BurgerButtonProps {
  isMenuOpen: boolean;
  handleMenuClick: () => void;
}

const BurgerButtonVariant = {
  open: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.3, ease: 'easeInOut' },
  },
  closed: (direction: number) => ({
    opacity: 0,
    y: `${direction * 30}`,
    transition: { duration: 0.3, ease: 'easeInOut' },
  }),
};

function BurgerButton({ isMenuOpen, handleMenuClick }: BurgerButtonProps) {
  return (
    <AnimatePresence mode="wait" initial={false}>
      <button
        type="button"
        data-collapse-toggle="navbar"
        aria-expanded={isMenuOpen}
        onClick={handleMenuClick}
        className={`${
          !isMenuOpen
            ? 'bg-primary text-on-primary border-transparent'
            : 'border-secondary text-background hover:bg-secondary'
        } z-10 flex h-[42px] w-28 flex-col items-center justify-center overflow-hidden rounded-[24px] border text-lg font-bold duration-300 hover:rounded-md`}
      >
        {!isMenuOpen ? (
          <motion.div
            key="open"
            className="flex w-full items-center justify-around gap-2  px-4 py-2"
            initial="closed"
            animate="open"
            exit="closed"
            custom={1}
            variants={BurgerButtonVariant}
          >
            <Icon icon="burger" className="h-6 w-6 pl-1" />
            <span className="self-center leading-none">Meny</span>
          </motion.div>
        ) : (
          <motion.div
            key="close"
            className="flex w-full items-center justify-around gap-2  px-4 py-2"
            initial="closed"
            animate="open"
            exit="closed"
            custom={-1}
            variants={BurgerButtonVariant}
          >
            <RiCloseLine className="h-6 w-6" />
            <span className="self-center pr-1 leading-none">Lukk</span>
          </motion.div>
        )}
      </button>
    </AnimatePresence>
  );
}

export default BurgerButton;
